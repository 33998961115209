import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
// import 'tailwindcss/tailwind.css';
import { BrowserRouter, Route, Routes, json } from "react-router-dom";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";

// import NavBar from './components/NavBar';
import Home from "./components/tailwindComponents/Home";
import ErrorPage from "./components/ErrorPage";
import "./components/css/style.css";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import NavBar from "./components/tailwindComponents/NavBar";
import UserLibrary from "./components/tailwindComponents/TailUserLibrary";

import 'spiketip-tooltip/spiketip.min.css'

import SignUp from "./components/tailwindComponents/SignUp";
import CustomisedTemplate from "./components/tailwindComponents/CustomisedTemplate";
import TermOfServices from "./components/tailwindComponents/TermOfServices"
import PrivacyPolicy from "./components/tailwindComponents/PrivacyPolicy";
import ContactUs from "./components/tailwindComponents/ContactUS";
import TechnicalFieldAndObject from "./components/tailwindComponents/TechnicalFieldAndObject";
import Background from "./components/tailwindComponents/Background";
import Claims from "./components/tailwindComponents/Claims";
import Summary from "./components/tailwindComponents/Summary";
import Abstract from "./components/tailwindComponents/Abstract";
import EmbodimentsDescription from "./components/tailwindComponents/EmbodimentsDescription";
import DrawingsDescription from "./components/tailwindComponents/DrawingsDescription";
import PushToApp from "./components/PushToApp";
import LandingPage from "./components/tailwindComponents/LandingPage"
import DescriptionofFiguresandDrawings from "./components/tailwindComponents/DescriptionofFiguresandDrawings";
import NewClaim from "./components/tailwindComponents/NewClaims";
import AdvanceClaim from "./components/tailwindComponents/AdvanceClaim";
import Pricing from "./components/tailwindComponents/Pricing";
import LandingTest from "./components/tailwindComponents/LandingTest";


const { REACT_APP_CREATE_AGREEMENT_API } = process.env
function App() {

  return (
    <BrowserRouter>
      <RenderOnAnonymous>
        <ReactNotifications />
        <NavBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/" element={<SignUp />} /> */}
          <Route path="/app/contact-us" element={<ContactUs />} />
          <Route path="/terms-of-service" element={<TermOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <NavBar />
        <ReactNotifications />
        <Routes>
          <Route path="/terms-of-service" element={<TermOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/app" element={<Home />} />
          <Route path="/app/contact-us" element={<ContactUs />} />
          <Route path="/app/customised-template" element={<CustomisedTemplate />} />
          <Route path="/app/dfd" element={<DescriptionofFiguresandDrawings />} />
          <Route path="/app/title-and-technical-field" element={<TechnicalFieldAndObject />} />
          <Route path="/app/background" element={<Background />} />
          <Route path="/app/claim-selection" element={<NewClaim />} />
          <Route path="/app/simple-claim" element={<Claims />} />
          <Route path="/app/advance-claim" element={<AdvanceClaim />} />
          <Route path="/app/summary" element={<Summary />} />
          <Route path="/app/abstract" element={<Abstract />} />
          <Route path="/app/drawings-and-ddpe" element={<DrawingsDescription />} />
          <Route path="/app/embodiments-description" element={<EmbodimentsDescription />} />
          <Route path="/app/library" element={<UserLibrary />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/test-landing" element={<LandingTest />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </RenderOnAuthenticated>
    </BrowserRouter>
  );
}

export default App;
