import React, { useEffect } from 'react'
import "../../components/css/landingpage.css"
import img1 from "../../assets/sittingattable.png"
import img2 from "../../assets/formSS.png"
import img3 from "../../assets/homeSS.png"
import { useDispatch } from 'react-redux'
import { getNavbar } from '../../Redux/Action/action'
import { redirect, useNavigate } from 'react-router'
import UserService from '../../services/UserService'
import FooterLanding from './FooterLanding'


import firstsectionbackground from "../../assets/firstsectionbackground.png"
import firstsectiongroup from "../../assets/firstsectiongroup.png"
import scriptListSS from "../../assets/scriptListSS.jpg"
import groupIcon from "../../assets/groupIcon.png"
import middleSS from "../../assets/middleSS.jpg"
import bussinessGroup from "../../assets/bussinessGroup.png"
import documentLibrary from "../../assets/draftReadyToGo2.png"
import forEveryone from "../../assets/forEveryone.png"
import scriptListBackground from "../../assets/scriptListBackground.png"
import scriptListBackgroundRight from "../../assets/scriptListBackgroundRight.png"
import { useLocation } from 'react-router'
import MyCarousel from './MyCarousel'


const { REACT_APP_REDIRECT_URI, REACT_APP_TIPSY_URL } = process.env;
const LandingTest = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    // const state = useSelector((state) => state.stateReducer);
    // useEffect(() => {
    //     hideNav()
    //     return () => dispatch(getNavbar(true))
    // }, [])
    // const hideNav = () => {
    //     dispatch(getNavbar(false))
    // }

    return (
        <section>
            <div className='container-fluid p-5 '>
                <h1 className='' style={{ marginTop: "10vh", fontSize: "40px" }}>My Patent Writer</h1>
                <h2 className='my-5'>Cutting-edge Artificial Intelligence for Writing Patents</h2>
                <h4 className=''>Developed in collaboration with patent professionals.</h4>
                <h4 className=''>Disrupting patents work with higher quality drafting and phenomenally shorter turnaround time.</h4>
                <h2 className='mt-5 mb-3'>Benefits</h2>

                <div className="alert alert-secondary text-lg" role="alert">
                    <p>Reduce time taken per patent specification to 2-4 hours.</p>
                    <p>Streamlined workflow.</p>
                    <p>USPTO compliant.</p>
                    <p>Cutting-edge artificial intelligence.</p>
                </div>

                <h2 className='mt-5 mb-3'>Confidentiality</h2>

                <div className="alert alert-secondary text-lg" role="alert">
                    <p>Inputs and outputs not used to train or improve models.</p>
                    <p>No third party access.</p>
                    <p>Encrypted and data-blind. .</p>
                    <p>Enterprise grade.</p>
                </div>


                <h2 className='mt-5 mb-3'>Watch Video</h2>
                <div>
                    <video src="" alt="video"></video>
                </div>


                <h2 className='mt-5 mb-3'>Published Patent Walk-through</h2>




                <h2 className='mt-5 mb-3'>Sign-up by choosing a suitable plan</h2>
                <div>
                    <video src="" alt="video"></video>
                </div>

                {/* <div>
                    <button>
                        Pricing and Plans
                    </button>
                </div> */}

            </div>
            <FooterLanding />
        </section>
    )
}

export default LandingTest