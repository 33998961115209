import React, { useEffect, useState } from 'react'
import jQuery from 'jquery'
import UserService from "../../services/UserService"
import axios from 'axios'
import Loader from "react-js-loader"
import { ProgressBar } from "react-loader-spinner"
import { useDispatch, useSelector } from 'react-redux'
import { getNotification, getUserDetails } from '../../Redux/Action/action'
import { useNavigate } from 'react-router'
import "../css/home.css"
import "../css/pricing.css"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env
const Pricing = () => {
    const state = useSelector(state => state.stateReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {

    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const subscriptionData = [
        {
            name: "Boutique",
            plan: "Starts USD 200 / month / user.",
            p1: "Ideal for specialists and small teams.",
            p2: "75,000+ words / month/ user. ",
            p3: "Access to all new features.",
            p4: "Email support."
        },
        {
            name: "Professional",
            plan: "Starts USD 1500 / month / team.",
            p1: "Ideal for professional drafting teams. ",
            p2: "500,000+ words / month / team.",
            p3: "Email and phone support.",
            p4: "Access to all new features.",
            p5: "Prompts adjustment (annual plans only)."
        },
        {
            name: "Enterprise ",
            plan: "Starts USD 5000 / month / org.",
            p1: "Ideal for innovating firms and organisations.",
            p2: "Patent specification drafting service.",
            p3: "Easy email services. No software adaptation. ",
            p4: "10+ drafts/ month",
            p5: "Prompts and style adjustments. ",
            p6: "Email and phone support. "
        },
    ]
    return (
        <section className="text-gray-600 body-font overflow-hidden d-flex justify-content-around node -v 
            ">
            {/* <div className="container px-5 mx-auto animatable fadeInUp"> */}
            <div className="container-fluid px-2 mx-auto">
                <div className="flex flex-wrap m-4 d-flex justify-content-center">
                    {
                        subscriptionData && subscriptionData.map((ele, ind) => {
                            return (
                                <div key={ind} className="bg-[#ECEEFF] rounded-xl my-4  mx-5">
                                    <div style={{ width: "350px" }} className="flex flex-col rounded-xl bg-white shadow-xl translate-x-4 translate-y-4 w-80">
                                        <div className="h-full p-6 rounded-lg border-2 border-indigo-500 border-solid border-black flex flex-col relative overflow-hidden">
                                            {/* <span className="bg-indigo-500 text-white px-3 py-1  text-xs absolute right-0 top-0 rounded-bl btn-dark">POPULAR</span> */}
                                            {/* <span className="bg-indigo-500 text-white px-3 py-1  text-xs absolute left-0 top-0 rounded-br btn-dark">Current Plan</span> */}


                                            <p style={{ fontSize: "30px" }} className=" mb-4 font-medium">{ele.name}</p>
                                            <p className="mb-4 font-medium text-info fw-bold">{ele.plan}</p>
                                            <p className="flex items-center text-gray-600 mb-2">
                                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0">
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                    </svg>
                                                </span>{ele.p1}
                                            </p>
                                            <p className="flex items-center text-gray-600 mb-2 ">
                                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0">
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                    </svg>
                                                </span>{ele.p2}
                                            </p>
                                            <p className="flex items-center text-gray-600 mb-2 ">
                                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0">
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                    </svg>
                                                </span>{ele.p3}
                                            </p>
                                            <p className="flex items-center text-gray-600 mb-2 ">
                                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0">
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                    </svg>
                                                </span>{ele.p4}
                                            </p>
                                            {ele.p5 && <p className="flex items-center text-gray-600 mb-2 ">
                                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0">
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                    </svg>
                                                </span>{ele.p5}
                                            </p>}
                                            {ele.p6 && <p className="flex items-center text-gray-600 mb-2 ">
                                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0">
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                    </svg>
                                                </span>{ele.p6}
                                            </p>}
                                            <button
                                                onClick={() => {
                                                    if (UserService.isLoggedIn()) navigate("/contact-us")
                                                }}
                                                style={ind === 0 ? { marginTop: "110px" } : ind === 1 ? { marginTop: "55px" } : {}}
                                                className="flex items-center text-black bg-[#F4F5FA] border-2 py-2 px-2 w-full focus:outline-none hover:bg-black-600 rounded custom-explore-button">Contact Us
                                                {/* // className="btn btn- custom-explore-button">Buy Now */}
                                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                </svg>
                                            </button>
                                            {/* <p className="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </section >
    )
}

export default Pricing
